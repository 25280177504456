<template>
	<router-view />
</template>

<script>
export default {
	name: 'Dashboard',
	computed: {},
	components: {},
};
</script>
